import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import TextHero from '../components/TextHero';
import SocialIcons, { SocialItemsData } from '../components/SocialIcons';
import SiteMeta from '../components/SiteMeta';
import ResourcesSection from '../components/ResourcesSection';
import Link from '../components/Link';
import { useCookie } from 'react-use';

// const SocialMessage = () => {
//   return (
//     <div className="form">
//       <div className="form__message">
//         <div className="form__message-social">
//           <p>Let's connect</p>
//           <SocialIcons
//             items={SocialItemsData.filter((item) =>
//               ['twitter', 'linkedin'].includes(item.slug),
//             )}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

const DemoThankYouPage = ({ data }) => {

  const COOKIE_NAME = 'PWC_PROEDGE_USER_GATE';
  // const COOKIE_VALUE_GATED = 0;
  const COOKIE_VALUE_UNGATED = '1';

  const [value, updateCookie, deleteCookie] = useCookie(COOKIE_NAME);

  const {
    metaTitle,
    metaDescription: { metaDescription },
    heroTitle,
    heroBody: { heroBody },
    formSection
  } = data.contentfulAncillaryPage;

  const {
    featuredResources,
    featuredResourcesTitle,
    featuredResourcesCtaLink,
    featuredResourcesCtaText,
    featuredResourcesSubheaderText: { featuredResourcesSubheaderText },
  } = data.contentfulLearnPage;

  const resourcesData = {
    title: featuredResourcesTitle,
    subtitle: featuredResourcesSubheaderText,
    ctaText: featuredResourcesCtaText,
    ctaUrl: featuredResourcesCtaLink,
    featuredResources: featuredResources
  };

  const TextHeroContent = {
    title: heroTitle,
    subtitle: heroBody
  }

  return (
    <Layout>
      <SiteMeta title={metaTitle} description={metaDescription} />
      <TextHero {...TextHeroContent} />
      <ResourcesSection {...resourcesData} />
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulAncillaryPage(slug: {eq: "demo-ty"}) {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroBody {
        heroBody
      }
      heroTitle
    }
    contentfulLearnPage{
      featuredResources {
        ...ResourceCard
      }
      featuredResourcesCtaText
      featuredResourcesTitle
      featuredResourcesCtaLink
      featuredResourcesSubheaderText {
        featuredResourcesSubheaderText
      }
    }
  }
`;

export default DemoThankYouPage;
